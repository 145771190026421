import {
    FunctionComponent,
    useCallback,
    useState,
    useEffect,
    useRef
} from 'react'
import { useNavigate } from 'react-router-dom'
import RightOverlayForm from '../../../components/RightOverlayForm'
import TextField from '@mui/material/TextField'
import {
    Grid,
    FormHelperText,
    Autocomplete,
    Modal,
    Box,
    Stack,
    Divider,
    Typography
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switcher from '../../../components/FormComponents/CustomSwitch'
import LabelField from '../../../components/FormComponents/CustomField'
import { ADD_CIRCLE_ICON, REMOVE_CIRCLE_ICON } from '../../../themes/icons'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import { extractError, returnApiToken, showErrorToast, showSuccessToast } from '../../../utils/utils'
import {
    createWarehouse,
    getWarehouseById,
    editWarehouse,
    getRecycleType
} from '../../../APICalls/warehouseManage'
import { set } from 'date-fns'
import { getLocation } from '../../../APICalls/getLocation'
import { get } from 'http'
import { getCommonTypes } from '../../../APICalls/commonManage'
import { FormErrorMsg } from '../../../components/FormComponents/FormErrorMsg'
import CustomField from '../../../components/FormComponents/CustomField'
import CustomTextField from '../../../components/FormComponents/CustomTextField'
import { createRecyc, createSubRecyc, deleteRecyc, deleteSubRecyc, updateRecyc, updateSubRecyc } from '../../../APICalls/ASTD/recycling'
import { STATUS_CODE } from '../../../constants/constant'

interface recyleSubtyeData {
    recycSubTypeId: string
    recyclableNameEng: string
    recyclableNameSchi: string
    recyclableNameTchi: string
    description: string
    remark: string
    status: string
    updatedAt: string
    updatedBy: string
}

interface recyleTypeData {
    createdAt: string
    createdBy: string
    description: string
    recycSubType: recyleSubtyeData[]
    recycTypeId: string
    recyclableNameEng: string
    recyclableNameSchi: string
    recyclableNameTchi: string
    remark: string
    status: string
    updatedAt: string
    updatedBy: string
    recycSubTypeId: string
    version: number
}

interface RecyclingFormatProps {
    drawerOpen: boolean
    handleDrawerClose: () => void
    action?: 'add' | 'edit' | 'delete'
    onSubmitData: (type: string) => void
    recyclableType: recyleTypeData[],
    selectedItem: recyleTypeData | null,
    mainCategory: boolean
    setDeleteModal: (value: boolean) => void
  }

const RecyclingFormat: FunctionComponent<RecyclingFormatProps> = ({
    drawerOpen,
    handleDrawerClose,
    action,
    onSubmitData,
    recyclableType,
    selectedItem,
    mainCategory,
    setDeleteModal
}) => {
    const { t } = useTranslation()
    const { i18n } = useTranslation()
    const currentLanguage = localStorage.getItem('selectedLanguage') || 'zhhk'
    const [errorMsgList, setErrorMsgList] = useState<string[]>([])
    const [openDelete, setOpenDelete] = useState<boolean>(false)

    const [recycleType, setRecycleType] = useState([])
    const [recycleSubType, setSubRecycleType] = useState({})
    const [contractList, setContractList] = useState<
        { contractNo: string; isEpd: boolean; frmDate: string; toDate: string }[]
    >([])
    const [pysicalLocation, setPysicalLocation] = useState<boolean>(false) // pysical location field
    const [status, setStatus] = useState<boolean>(true) // status field
    const [trySubmited, setTrySubmitted] = useState<boolean>(false)
    const [tChineseName, setTChineseName] = useState<string>('')
    const [sChineseName, setSChineseName] = useState<string>('')
    const [englishName, setEnglishName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [remark, setRemark] = useState<string>('')
    const [isMainCategory, setMainCategory] = useState<boolean>(true)
    const [chosenRecyclableType, setChosenRecyclableType] = useState<string>('')
    const [subTypeId, setSubTypeId] = useState<string>('')
    const [mainTypeId, setMainTypeId] = useState<string>('')
    const [version, setVersion] = useState<number>(0)
    const [validation, setValidation] = useState<{ field: string; error: string, dataTestId: string }[]>([])
    const isInitialRender = useRef(true) // Add this line
    const navigate = useNavigate();

    useEffect(() => {
        i18n.changeLanguage(currentLanguage)
    }, [i18n, currentLanguage])
    
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        
        setTrySubmitted(false)
        if (action === 'edit') {
            setTrySubmitted(false)
            if (selectedItem !== null && selectedItem !== undefined) {
                console.log(selectedItem, 'selected')
                if (!mainCategory) {
                    const parentData = recyclableType.find(value => value.recycSubType.some(subType => subType.recycSubTypeId === selectedItem.recycSubTypeId));
                    setSubTypeId(selectedItem.recycSubTypeId);
                    setChosenRecyclableType(parentData ? parentData.recycTypeId : '');
                } else {
                    setMainTypeId(selectedItem.recycTypeId);
                }
                setTChineseName(selectedItem.recyclableNameTchi);
                setSChineseName(selectedItem.recyclableNameSchi);
                setEnglishName(selectedItem.recyclableNameEng);
                setDescription(selectedItem.description);
                setRemark(selectedItem.remark);
                setMainCategory(mainCategory);
                setVersion(selectedItem.version)
            }
        } else if (action === 'add') {
            resetForm();
        }
    }, [selectedItem, action, mainCategory, recyclableType]);

    const resetForm = () => {
        setTChineseName('')
        setSChineseName('')
        setEnglishName('')
        setDescription('')
        setRemark('')
        setMainCategory(true)
        setChosenRecyclableType('')
        setSubTypeId('')
    }

    const checkString = (s: string) => {
        if (!trySubmited) {
            //before first submit, don't check the validation
            return false
        }
        return s == ''
    }

    const checkNumber = (s: number) => {
        if (!trySubmited) {
            //before first submit, don't check the validation
            return false
        }
        return s == 0
    }

    const getFormErrorMsg = () => {
        const errorList: string[] = []
        validation.map((item) => {
          errorList.push(`${item.error}`)
        })
        setErrorMsgList(errorList)
    
        return ''
      }
      
    useEffect(() => {
        const tempV: {field: string; error: string; dataTestId: string}[] = []

        tChineseName.trim() === '' &&
        tempV.push({
            field: `${t('packaging_unit.traditional_chinese_name')}`,
            error: `${t(
            'add_warehouse_page.shouldNotEmpty'
            )}`,
            dataTestId: 'astd-recyclable-form-tc-err-warning-4543'
        })

        sChineseName.trim() === '' &&
        tempV.push({
            field: `${t('packaging_unit.simplified_chinese_name')}`,
            error: `${t(
            'add_warehouse_page.shouldNotEmpty'
            )}`,
            dataTestId: 'astd-recyclable-form-sc-err-warning-7195'
        })

        englishName.trim() === '' &&
        tempV.push({
            field: `${t('packaging_unit.english_name')}`,
            error: `${t(
            'add_warehouse_page.shouldNotEmpty'
            )}`,
            dataTestId: 'astd-recyclable-form-en-err-warning-2471'
        })

        if (isMainCategory === false) {
            chosenRecyclableType.trim() === '' &&
            tempV.push({
                field: `${t('recycling_unit.main_category')}`,
                error: `${t(
                'add_warehouse_page.shouldNotEmpty'
                )}`,
                dataTestId: 'astd-recyclable-form-choose-main-select-err-warning-8595'
            })
        }

        setValidation(tempV)
    }, [tChineseName, sChineseName, englishName, isMainCategory, chosenRecyclableType, i18n, currentLanguage])

    const handleDelete = async () => {
        const token = returnApiToken()
        
        const recyclingForm = {
            status: 'INACTIVE',
            updatedBy: token.loginId,
            version: version
        }

        try {
            if (isMainCategory) {
                setDeleteModal(true)
                // const response = await deleteRecyc(recyclingForm, mainTypeId)
                // if (response) {
                //     showSuccessToast(t('notify.successDeleted'))
                //     onSubmitData('recycle')
                // }
            } else {
            const response = await deleteSubRecyc(recyclingForm, subTypeId)
                if (response) {
                    showSuccessToast(t('notify.successDeleted'))
                    onSubmitData('recycle')
                }
            }
        } catch (error:any) {
            const {state} =  extractError(error)
            if (state.code === STATUS_CODE[503]) {
                navigate('/maintenance')
              } else if (state.code === STATUS_CODE[409]){
                showErrorToast(error.response.data.message);
              }
           
        }
    }
    
    const handleSubmit = () => {
        const { loginId } = returnApiToken();
        
        const addRecyclingForm = {
            recyclableNameTchi:tChineseName, 
            recyclableNameSchi: sChineseName,
            recyclableNameEng: englishName,
            description: description,
            remark: remark,
            status: 'ACTIVE',
            createdBy: loginId,
            updatedBy: loginId,
            ...(action === 'edit' && {version: version})
        }

        const isError = validation.length == 0
        getFormErrorMsg()

        if (validation.length == 0) {
            action == 'add' ? createRecycData(addRecyclingForm) : editRecycData(addRecyclingForm)

            setValidation([])
        } else {
            setTrySubmitted(true)
        }
    }

    const createRecycData = async (addRecyclingForm: any) => {
        try {
            if (isMainCategory) {
                const response = await createRecyc(addRecyclingForm)
                if (response) {
                    showSuccessToast(t('notify.successCreated'))
                    onSubmitData('recycle')
                }
            } else {
                const response = await createSubRecyc(addRecyclingForm, chosenRecyclableType)
                if (response) {
                    showSuccessToast(t('notify.successCreated'))
                    onSubmitData('recycle')
                }
            }
        } catch (error:any) {
            const {state} = extractError(error)
            if(state.code === STATUS_CODE[503] ){
                navigate('/maintenance')
            } else if (state.code === STATUS_CODE[409]) {
                const errorMessage = error.response.data.message
                if (errorMessage.includes('[recyclableNameDuplicate]')) {
                  showErrorToast(handleDuplicateErrorMessage(errorMessage))
                } else {
                  showErrorToast(error.response.data.message);
                }
              }
        }
    }
    const editRecycData = async (addRecyclingForm: any) => {
        try {
            if (isMainCategory) {
                const response = await updateRecyc(addRecyclingForm, mainTypeId)
                if (response) {
                    showSuccessToast(t('notify.SuccessEdited'))
                    onSubmitData('recycle')
                }
            } else {
            const response = await updateSubRecyc(addRecyclingForm, subTypeId)
                if (response) {
                    showSuccessToast(t('notify.SuccessEdited'))
                    onSubmitData('recycle')
                }
            }
        } catch (error:any) {
            const {state} = extractError(error)
            if (state.code === STATUS_CODE[503]) {
                navigate('/maintenance')
              } else if (state.code === STATUS_CODE[409]) {
                const errorMessage = error.response.data.message
                if (errorMessage.includes('[recyclableNameDuplicate]')) {
                  showErrorToast(handleDuplicateErrorMessage(errorMessage))
                } else {
                  showErrorToast(error.response.data.message);
                }
              }
            
        }
    }
    const handleDuplicateErrorMessage = (input: string) => {
        const replacements: { [key: string]: string } = {
          '[tchi]': 'Traditional Chinese Name',
          '[eng]': 'English Name',
          '[schi]': 'Simplified Chinese Name'
        };
      
        let result = input.replace(/\[recyclableNameDuplicate\]/, '');
      
        const matches = result.match(/\[(tchi|eng|schi)\]/g);
      
        if (matches) {
          const replaced = matches.map(match => replacements[match as keyof typeof replacements]);
      
          let formatted: string;
          if (replaced.length === 1) {
            formatted = replaced[0];
          } else if (replaced.length === 2) {
            formatted = replaced.join(' and ');
          } else if (replaced.length === 3) {
            formatted = `${replaced[0]}, ${replaced[1]} and ${replaced[2]}`;
          }
      
          result = result.replace(/\[(tchi|eng|schi)\]+/, formatted!);
      
          result = result.replace(/\[(tchi|eng|schi)\]/g, '');
        }
      
        return result.trim();
      };

    return (
        <div className="add-vehicle">
            <RightOverlayForm
                open={drawerOpen}
                onClose={handleDrawerClose}
                anchor={'right'}
                action={action}
                headerProps={{
                    title:
                        action == 'add'
                            ? t('top_menu.add_new')
                            : action == 'delete'
                                ? t('common.delete')
                                : '',
                    subTitle: t('recycling_unit.recyclable_subtype_semi_complete'),
                    submitText: t('add_warehouse_page.save'),
                    cancelText: t('add_warehouse_page.delete'),
                    onCloseHeader: handleDrawerClose,
                    onSubmit: handleSubmit,
                    onDelete: handleDelete,
                    deleteText: t('common.deleteMessage')
                }}
            >
                <Divider></Divider>
                <Box sx={{ marginX: 2 }}>
                    <Box sx={{ marginY: 2 }}>
                        <CustomField label={t('packaging_unit.traditional_chinese_name')} mandatory>
                            <CustomTextField
                                id="tChineseName"
                                value={tChineseName}
                                disabled={action === 'delete'}
                                placeholder={t('packaging_unit.traditional_chinese_name_placeholder')}
                                onChange={(event) => setTChineseName(event.target.value)}
                                error={checkString(tChineseName)}
                                dataTestId='astd-recyclable-form-tc-input-field-5560'
                            />
                        </CustomField>
                    </Box>
                    <Box sx={{ marginY: 2 }}>
                        <CustomField label={t('packaging_unit.simplified_chinese_name')} mandatory>
                            <CustomTextField
                                id="sChineseName"
                                value={sChineseName}
                                disabled={action === 'delete'}
                                placeholder={t('packaging_unit.simplified_chinese_name_placeholder')}
                                onChange={(event) => setSChineseName(event.target.value)}
                                error={checkString(sChineseName)}
                                dataTestId='astd-recyclable-form-sc-input-field-2575'
                            />
                        </CustomField>
                    </Box>
                    <Box sx={{ marginY: 2 }}>
                        <CustomField label={t('packaging_unit.english_name')} mandatory>
                            <CustomTextField
                                id="englishName"
                                value={englishName}
                                disabled={action === 'delete' }
                                placeholder={t('packaging_unit.english_name_placeholder')}
                                onChange={(event) => setEnglishName(event.target.value)}
                                error={checkString(englishName)}
                                dataTestId='astd-recyclable-form-en-input-field-4031'
                            />
                        </CustomField>
                    </Box>
                    <Box sx={{ marginY: 2 }}>
                        <CustomField label={t('recycling_unit.main_category')} mandatory>
                            <Switcher
                                onText={t('add_warehouse_page.yes')}
                                offText={t('add_warehouse_page.no')}
                                disabled={action === 'delete' || (action === 'edit')}
                                defaultValue={isMainCategory}
                                setState={(newValue) => {
                                    setMainCategory(newValue);
                                    newValue === false && setChosenRecyclableType('')
                                }}
                                dataTestId='astd-recyclable-form-type-boolean-button-4961'
                            />
                        </CustomField>
                    </Box>
                    {!isMainCategory && (
                        <Box sx={{ marginY: 2 }}>
                            <CustomField label={t('recycling_unit.main_category')} mandatory>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch ">
                                        <FormControl sx={{ m: 1, width: '100%' }}>
                                            <Select
                                                value={chosenRecyclableType}
                                                onChange={(event: SelectChangeEvent<string>) => setChosenRecyclableType(event.target.value)}
                                                displayEmpty
                                                disabled={action === 'delete'|| (action === 'edit')}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ borderRadius: '12px' }}
                                                error={checkString(chosenRecyclableType)}
                                                data-testid='astd-recyclable-form-choose-main-select-button-2451'
                                            >
                                                <MenuItem value="">
                                                <em>-</em>
                                                </MenuItem>
                                                {recyclableType.length > 0 ? (recyclableType.map((item, index) => (
                                                <MenuItem value={item.recycTypeId} key={index}>
                                                    {currentLanguage === 'zhhk'
                                                    ? item.recyclableNameTchi
                                                    : currentLanguage === 'zhch'
                                                    ? item.recyclableNameSchi
                                                    : item.recyclableNameEng}
                                                </MenuItem>
                                                ))): 
                                                <MenuItem disabled value="">
                                                <em>{t('common.noOptions')}</em>
                                                </MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </CustomField>
                        </Box>
                    )}
                    <CustomField label={t('packaging_unit.introduction')}>
                        <CustomTextField
                            id="description"
                            placeholder={t('packaging_unit.introduction_placeholder')}
                            onChange={(event) => setDescription(event.target.value)}
                            multiline={true}
                            defaultValue={description}
                            dataTestId='astd-recyclable-form-intro-input-field-7653'
                        />
                    </CustomField>
                    <CustomField label={t('packaging_unit.remark')}>
                        <CustomTextField
                            id="remark"
                            placeholder={t('packaging_unit.remark_placeholder')}
                            onChange={(event) => setRemark(event.target.value)}
                            multiline={true}
                            defaultValue={remark}
                            dataTestId='astd-recyclable-form-remark-input-field-3725'
                        />
                    </CustomField>
                    <Grid item sx={{ width: '92%' }}>
                        {trySubmited &&
                            validation.map((val, index) => (
                            <FormErrorMsg
                                key={index}
                                field={t(val.field)}
                                errorMsg={val.error}
                                type={'error'}
                                dataTestId={val.dataTestId}
                            />
                            ))}
                    </Grid>
                </Box>
            </RightOverlayForm>
        </div>
    )
}

let styles = {
    textField: {
        borderRadius: '10px',
        fontWeight: '500',
        '& .MuiOutlinedInput-input': {
            padding: '15px 20px',
            margin: 0
        }
    },
    textArea: {
        borderRadius: '10px',
        fontWeight: '500',
        '& .MuiOutlinedInput-input': {
            padding: 0,
            margin: 0
        }
    },
    inputState: {
        '& .MuiOutlinedInput-root': {
            margin: 0,
            '&:not(.Mui-disabled):hover fieldset': {
                borderColor: '#79CA25'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#79CA25'
            }
        }
    },
    dropDown: {
        '& .MuiOutlinedInput-root-MuiSelect-root': {
            borderRadius: '10px'
        }
    },
    modal: {
        position: 'absolute',
        top: '50%',
        width: '34%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        height: 'fit-content',
        padding: 4,
        backgroundColor: 'white',
        border: 'none',
        borderRadius: 5,

        '@media (max-width: 768px)': {
            width: '70%' /* Adjust the width for mobile devices */
        }
    }
}

export default RecyclingFormat