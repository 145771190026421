// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* to declare global style , eg: btn, modal etc style need to be share */

.primary-btn {
  padding: 8px 30px;
  color: white;
  background: #79ca25;
  border-radius: 20px;
  border: 1px solid var(--green-pale, #79ca25);
  font-weight: bold;
}

.secondary-btn {
  padding: 8px 30px;
  color: #79ca25;
  font-weight: bold;
  background: white;
  border-radius: 20px;
  border: 1px solid var(--green-pale, #79ca25);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Base.css"],"names":[],"mappings":"AAAA,wEAAwE;;AAExE;EACE,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,4CAA4C;EAC5C,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,4CAA4C;AAC9C","sourcesContent":["/* to declare global style , eg: btn, modal etc style need to be share */\n\n.primary-btn {\n  padding: 8px 30px;\n  color: white;\n  background: #79ca25;\n  border-radius: 20px;\n  border: 1px solid var(--green-pale, #79ca25);\n  font-weight: bold;\n}\n\n.secondary-btn {\n  padding: 8px 30px;\n  color: #79ca25;\n  font-weight: bold;\n  background: white;\n  border-radius: 20px;\n  border: 1px solid var(--green-pale, #79ca25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
