// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-button {
  z-index: 1200;
  position: fixed;
  left: 20px;
  top: 16px;
}

.icon-menu-active > .MuiSvgIcon-root {
  color: #79ca25;
}

.text-menu-active {
  color: #79ca25;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MainDrawer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,UAAU;EACV,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".menu-button {\n  z-index: 1200;\n  position: fixed;\n  left: 20px;\n  top: 16px;\n}\n\n.icon-menu-active > .MuiSvgIcon-root {\n  color: #79ca25;\n}\n\n.text-menu-active {\n  color: #79ca25;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
